import React, { useEffect, useState } from 'react';

import { Routes, Route, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import './App.css';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home';
import LancelyComponent from './components/Portfolio/LancelyComponent';
import Portfolio from './components/Portfolio/Portfolio';
import Resume from './components/Resume/Resume';

function App() {

  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    if (path !== location.pathname) {
      mobileToggle();
      setPath(location.pathname);
    }
  }, [location.pathname])


  const mobileToggle = (e) => {
    document.getElementById('content').classList.toggle('hidden');
    document.getElementById('mobileNav').classList.toggle('fadeIn');
  }

  useEffect(() => {
    const loader = document.querySelector(".loader");
    loader.addEventListener("animationend", (ev) => {
      if (ev.type === "animationend") {
        loader.style.display = "none";
      }
    }, false);

    setTimeout(() => {
      loader.classList.add("fade-out");
    }, 250);

  }, [])


  return (
    <>
      <div className="loader">
        <div className="la-ball-grid-pulse la-dark la-2x">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div id="mobileNav">
        <div className="close" onClick={() => {
          mobileToggle()
        }}>
          &#x2573;
        </div>
        <div className="mobileNavigation">
          <div className="lead">
            Navigation
          </div>
          <ul>
            <li className={path === '/' ? 'active' : '' }>
              <Link to="/">Home</Link>
            </li>
            <li className={path === '/about' ? 'active' : '' }>
              <Link to="/about">About</Link>
            </li>
            <li className={path === '/resume' ? 'active' : '' }>
              <Link to="/resume">Resume</Link>
            </li>
            <li className={path === '/portfolio' ? 'active' : '' }>
              <Link to="/portfolio">Portfolio</Link>
            </li>
            <li className={path === '/contact' ? 'active' : '' }>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <ul className="mobile-social-nav">
                <li>
                  <a className="nav-link" href="https://www.linkedin.com/in/omarquazi" target="_blank">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="https://github.com/blacksector" target="_blank">
                    <i className="fab fa-github"></i>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="https://twitter.com/omarquazi" target="_blank">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="https://instagram.com/omarquazi" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div id="content" className="transformToLeft hidden">
        <header>
          <a className={`mobile-toggler ${path !== '/' ? 'mobile-toggler-other' : ''}`} onClick={() => {
            mobileToggle()
          }} aria-label="Toggle navigation">
            MENU <span>&#9776;</span>
          </a>
        </header>

        <main className={path !== '/' ? 'lighter-background' : ''}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/lancely" element={<LancelyComponent />}/>
            <Route path="/portfolio/:projectId" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
