import React from 'react'

import notie from 'notie'

import './Contact.css';

function Contact() {

    const handleSubmit = (e) => {
        e.preventDefault();
        let myForm = document.getElementById('contactForm');
        let formData = new FormData(myForm)
        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        }).then(() => {
            notie.alert({
                type: "success",
                text: "Form submitted, I'll be in touch soon!",
                stay: false,
                time: 5,
                position: "top"
            });
            myForm.reset();
        }).catch((error) => {
            notie.alert({
                type: "warning", // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: "Failed to submit form, something went wrong. Please try again later.",
                stay: false, // optional, default = false
                time: 5, // optional, default = 3, minimum = 1,
                position: "top" // optional, default = 'top', enum: ['top', 'bottom']
            });
        });
    }

    return (
        <div className="container" style={{ paddingTop: '10%' }}>
            <div className="row">
                <div className="spacer"></div>
                <div className="col col-12 col-expand">
                    <div className="display-4 text-bold text-indigo">Contact Me</div>
                    <p>📅 Get in touch to schedule an appointment! You can also <a href="mailto:omar@quazi.co?Subject=Project+Enquiry">send an email</a> or shoot me a text <a href="tel:+16473606627">+1 (647) 360-OMAR (6627)</a>.</p>

                    <form id="contactForm" name="contact" className="form-modern" method="POST" data-netlify="true" onSubmit={handleSubmit}>
                        <input type="hidden" name="form-name" value="contact_form" />

                        <label>Hey, my name is <input type="text" name="name" placeholder="Jane Doe" required />.</label>

                        <label>And I am looking to
                            <select name="requirement[]" required>
                                <option disabled selected>task here</option>
                                <option value="app">build an app</option>
                                <option value="website">design a website</option>
                                <option value="bug">fix a bug</option>
                                <option value="consultant">ask a few questions</option>
                            </select>.
                        </label>
                        <br />
                        <label>Please get in touch with me here: <input type="email" name="email" placeholder="Your email" required />!</label>
                        <p>
                            <button className="btn btn-indigo" type="submit">Send Enquiry &#8594;</button>
                        </p>
                    </form>
                </div>
            </div>
            <div className="spacer"></div>
            <div className="row">
                <div className="col col-12 col-expand">
                    <div className="display-4 text-bold text-indigo">
                        A few things clients
                        <br />
                        normally ask me:</div>
                    <details>
                        <summary>What is your work discipline like? Do you overbook yourself / work on more than one project?</summary>
                        I have set times when I work on projects that I like to call "sprints"! As for more than one project, yes, I do! But I schedule the days and hours separately and allocate them according to the project requirements, deadlines, etc.!
                    </details>

                    <details>
                        <summary>What is your fee? Is it hourly, milestone based, or fixed?</summary>
                        My rate depends on the amount of work required; as each project is different and can vary based on needs and requirements, I tend to charge a fixed fee rather than an hourly rate to keep things simple. For much larger projects, sometimes I will charge a milestone based fee.
                    </details>

                    <details>
                        <summary>How long does one web development project take to complete from start to finish?</summary>
                        Again, much like my previous answer: it depends. The time frame will be different if you need a simple static site versus one that requires shopping cart integration and blogs! But on average, projects will take anywhere from 3-6 weeks.
                    </details>

                    <details>
                        <summary>What services are provided?</summary>
                        I am a full-stack developer, which means I have a vast knowledge base surrounding application and software development. I can do everything from deploying servers, spinning up instances for REST APIs to developing robust applications with Angular, Vue, React, or even AlpineJS (more recently). I also have dabbled in mobile application development with Ionic (Angular), React Native, and Flutter / Dart.
                    </details>

                    <details>
                        <summary>Will I be able to make edits on my own?</summary>
                        Suppose we set up a CMS-like backend (WordPress, Joomla, etc.) then absolutely! Again, based on your specifications, this answer will vary. If it is a static site, I don't usually use WordPress to save you a lot of money in the future with web hosting. On the other hand, if you require more features, it will increase development costs, but yes, you will be able to edit your pages by yourself.
                    </details>

                    <details>
                        <summary>Do you outsource projects or complete them in house?</summary>
                        Everything is done in house.
                    </details>

                    <details>
                        <summary>How many design revisions do I have?</summary>
                        We are all human here :) I like to think I do my best according to the specifications given to me, but in the event that you do not like the design, I make a reasonable number of revisions and fix those minor issues.
                    </details>

                </div>
            </div>
            <div className="spacer"></div>
        </div>
    )
}

export default Contact
