import React from 'react'

import resumeData from './resumeData.json';

import moment from 'moment';

import './Resume.css';

function Resume() {

    const calculateTimeDifference = (date1, date2) => {
        let dur = moment.duration(date2 === "Present" ? moment().diff(moment(date1)) : moment(date2).diff(moment(date1)));

        let months = Math.ceil(dur.asMonths())
        let years = Math.floor(months / 12);
        months = months >= 12 ? months % 12 : months;

        return {
            years, months
        }
    }

    return (
        <div className="container" style={{ paddingTop: '10%' }}>
            <div className="row h-100">
                <div className="col col-12 col-expand">
                    <div className="display-4 text-bold text-indigo">Resume</div>
                    <p>You can get in touch with me <a href="/contact">here</a> or you can download my resume:</p>
                    <div className="resume-blocks">
                        <a href="/assets/files/resume.pdf" className="btn btn-indigo">Download Resume</a>
                    </div>
                </div>
                <div id="workExperience" className="col col-6 col-expand">
                    <p className="display-6">Work Experience</p>
                    {
                        resumeData.work.map((work, index) => {
                            let dur = calculateTimeDifference(work.startDate, work.endDate)
                            return (
                                <div className={`resume-blocks`} key={index}>
                                    <p className="lead">
                                        <a href={work.website} target="_blank">
                                            {work.company}
                                        </a>
                                    </p>
                                    <p>{work.position}</p>
                                    <p>
                                        {work.startDate} - {work.endDate || "Present"}
                                        &nbsp;
                                        ({dur.years > 0 ? `${dur.years} yr ` : ''}{dur.months > 0 ? `${dur.months} mos` : ''})
                                    </p>
                                    {
                                        work.highlights.length > 0 && <ul>
                                            {
                                                work.highlights.map((highlight, index) => {
                                                    return (
                                                        <li key={index}>{highlight}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className="col col-6 col-expand">
                    <div id="education">
                        <p className="display-6">Education</p>
                        {
                            resumeData.education.map((education, index) => {
                                return (
                                    <div className={`resume-blocks`} key={index}>
                                        <p className="lead">{education.institution}</p>
                                        <p>{education.area} - {education.studyType}</p>
                                        <p>{education.startDate} - {education.endDate || "Present"}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div id="skills">
                        <p className="display-6">Skills</p>
                        {
                            resumeData.skills.length > 0 && <div className={`resume-blocks`}>
                                <ul>
                                    {
                                        resumeData.skills.map((skill, index) => {
                                            return (
                                                <li key={index}>{skill}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                    <div id="tools">
                        <p className="display-6">Tools / Frameworks</p>
                        {
                            resumeData.tools.length > 0 && <div className={`resume-blocks`}>
                                <ul>
                                    {
                                        resumeData.tools.map((tool, index) => {
                                            return (
                                                <li key={index}>{tool}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div id="awards" className="col col-6 col-expand">
                    <p className="display-6">Awards</p>
                    {
                        resumeData.awards.map((award, index) => {
                            return (
                                <div className={`resume-blocks`} key={index}>
                                    <p className="lead">{award.awarder}</p>
                                    <p>{award.title}</p>
                                    <p>{award.date}</p>
                                    <p>{award.summary}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div id="projects" className="col col-6 col-expand">
                    <p className="display-6">Projects</p>

                    {
                        resumeData.projects.map((project, index) => {
                            return (
                                <div className={`resume-blocks`} key={index}>
                                    <p className="lead">
                                        <a href={project.website} target="_blank">
                                            {project.name}
                                        </a>
                                    </p>
                                    <p>{project.summary}</p>

                                    {
                                        project.skills.length > 0 && <ul>
                                            {
                                                project.skills.map((skill, index) => {
                                                    return (
                                                        <li key={index}>{skill}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    }
                                </div>
                            )
                        })
                    }

                    <div className={`resume-blocks`}>
                        <a className="btn btn-indigo" href="/portfolio">See More Projects</a> <div className="spacer"></div>
                        <div className="img-responsive padding-3"><a href="/contact"><img src="/assets/imgs/circle.png" /></a></div>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default Resume
