import React from 'react'

import './LancelyComponent.css';

function LancelyComponent() {
    return (
        <div className="container lighter-background">
            <div className="row">
                <div className="col col-12 col-expand text-left hero">

                    <div className="spacer"></div>
                    <div className="display-4 text-bold text-blue center-on-mobile">Lancely Co</div>
                    <div className="img-responsive">
                        <img src={'/assets/imgs/portfolio/lancely-co.png'} alt="Screenshot of Lancely" />
                    </div>

                    <p className="lead text-blue">Links</p>
                    <a href="#project-summary">Project Summary</a> &nbsp;
                    <a href="#project-vision">Project Vision</a> &nbsp;
                    <a href="#project-requirements">Project / Business Requirements</a> &nbsp;
                    <a href="##project-plan">Project Plan</a> &nbsp;
                    <a href="#wireframes">Wireframes</a> &nbsp;


                     <a id="project-summary"></a>
                    <p className="display-6 text-blue">
                        Project Summary
                    </p>

                    <p>
                        <span className={`lead`}>Company Name: </span>
                        Lancely Co
                    </p>

                    <p>
                        <span className={`lead`}>About Company: </span>
                        Lancely Co. provides software solutions for small and medium sized businesses and freelancers.
                    </p>

                    <p>
                        <span className={`lead`}>Company Contact and Website: </span>
                        <br />
                        Website: <a href="https://lancely.co" target="_blank">www.lancely.co</a>
                        <br />
                        Phone: <a href="tel:+18558442371">+1 (855) 844-2371</a>
                        <br />
                        Email: <a href="mailto:support@lancely.co">support@lancely.co</a>
                    </p>

                    <p>
                        <span className={`lead`}>Team Member Names: </span>
                        <ul>
                            <li>Omar Quazi</li>
                            <li>Ali Jafari</li>
                            <li>Sara Tayaranian</li>
                            <li>Sogand Samani</li>
                            <li>Brukti Adugna</li>
                        </ul>
                    </p>

                    <p>
                        <span className={`lead`}>Project Title: </span>
                        Lancely Co App
                    </p>

                    <p>
                        <span className={`lead`}>Project Description: </span> <br />
                        We are building the all-in-one solution for photographers and videographers doing freelancing work. Lancely Co offers its users a modern platform to handle appointments, payments, scheduling, disputes, and everything else under one application. You can even send automagically watermarked sample images to your clients to select photos! Lancely Co is the new way to manage a freelancing business.
                    </p>

                    <p>
                        <span className={`lead`}>Problem / Opportunity Assessment: </span>
                        <ol>
                            <li>No easy way to manage freelancing businesses that are related to photography</li>
                            <li>The solutions that are available cost $$$ and don’t include the right features</li>
                        </ol>
                    </p>

                    <p>
                        <span className={`lead`}>Desired Project Outcomes / Requirements: </span>
                        <ol>
                            <li>Develop a user-friendly UI/UX where it is easier to manage files</li>
                            <li>Provide a solution for users to abide by regulations by providing the needed documentation (legal, model release, etc.)</li>
                            <li>Easy way to invoice and receive payments from their clients</li>
                            <li>Fast way to schedule work and allow clients to see availabilities</li>
                        </ol>
                    </p>


                     <a id="project-vision"></a>
                    <p className="display-6 text-blue">
                        Project Vision
                    </p>

                    <p>
                        <span className="lead">Introduction:</span>
                        <br />
                        Lancely Co (from the word free-lance) is a service that takes a modern approach to freelance work. Right now, more than ever, people are doing freelancing working and trying to manage and maintain a side business to help with costs around the home, especially during an economic downturn like now with COVID-19. Freelancers will manage/schedule appointments, payments, disputes, upload work and distribute them as well. Lancely Co would offer its clients different plans that include paying for more storage, creating legal templates (model/media release forms), receiving legal counsel, and everything else from one place. This document will outline an overview of the things Lancely Co will do for its users.
                    </p>

                    <p>
                        <span className="lead">Business Opportunity:</span>
                        <br />

                        Over the past year, while Omar has worked as a freelancer, he used different software and managed photos repetitively and tediously. The general workflow involved invoicing clients via e-Transfer, Square Up, or other methods. Once the job is completed, he would export images, watermark them, upload pictures to Google Drive or some other online cloud storage service, then send them to his clients. From there, my clients would select photos and send him the image file names (e.g. IMG_5924.JPG), and then he would manually find, select, and then edit those files.
                        <br /><br />
                        This task was very tedious and took out from his valuable time, which could have easily been used to expand his business. Here is where Lancely Co comes in:
                        <br /><br />
                        Lancely Co has a built-in solution to allow you to send proposals with contracts, model release forms, schedule appointments (text reminders, calendar invites), maps and locations for meeting spots, accept payments (Stripe) or manually collect money received from clients. To expand on the problems, Omar faced as a freelancer, Lancely Co has a simple way to upload images that are converted to smaller file sizes and watermarked. Once uploaded, the platform sends an invite to clients to view the project and “like” the images they want edited (no more messy file names). Once complete, the clients can notify their photographer/videographer the client has selected their preferred images. Freelancers can quickly edit the photos, upload the edited images to the platform, and promptly notify their clients of task completion.
                        <br /><br />
                        Lancely Co would simplify the whole freelancing workflow. The platform keeps records of all communications, invoices, goals, and contracts, allowing our customers to handle any legal disputes. Often, freelancers work with clients who withhold money or pay unfairly outside of the contract. Lancely Co would overcome this problem by having the customer deposit the full amount (or less – we would use AI + previous purchases to determine the reputation and trustworthy- ness of the clients). Once the job is done, the client can rest assured that the task is completed, the payment is sent to the freelancer who can trust the Lancely platform to have protected them if something goes sideways.
                    </p>

                    <p>
                        <span className="lead">A Successful Solution Would Be:</span>
                        <br />
                        To build a platform that takes the stress out of managing a freelancing business by offering invoicing, scheduling, payment management, and file management (major pain points).
                    </p>

                    <p className="lead">SWOT Analysis</p>

                    <table className="table table-borderless table-striped">
                        <tr>
                            <td>
                                <p className="text-blue">
                                    Strengths
                                </p>
                                <ul>
                                    <li>
                                        We do freelance management well. Our team has a freelancer, which means we understand our market very well and can use the inside knowledge to create products that other freelancers will love.
                                    </li>
                                    <li>
                                        Our platform offers additional capabilities that many photographers/videographers need.
                                    </li>
                                    <li>We do the same things our competitors  do, better, for cheaper, and we provide additional capabilities.</li>
                                </ul>
                            </td>
                            <td>
                                <p className="text-blue">
                                    Weaknesses
                                </p>
                                <ul>
                                    <li>
                                        Our most significant gap is access to reliable and affordable lawyers to help create and draft contracts + help our clients. We do not have issues in terms of human resources/staff. As the founder, I have software development, photography, and marketing experience.
                                    </li>
                                    <li>
                                        This may prove not so easy a task if we do not have enough funding or resources to draft contracts and hire legal help.
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p className="text-blue">
                                    Opportunities
                                </p>
                                <ul>
                                    <li>
                                        There is minimal competition in the space and no competition at all in the niche photographer/videographer sector.
                                    </li>
                                    <li>
                                        Most of the services offered by our competitors charge ridiculously high prices. Most people forget that freelancers only get work when they find work. Therefore, many freelancers are not keen on paying $40/month for a service they can pay $5.99/month for and not be negatively affected if they do not receive a contract/gig one month. This gives us a considerable advantage over any competitor.
                                    </li>
                                    <li>
                                        Weddings, family events, birthdays, and more are huge for all photographers and videographers. Wedding season is a great time to market to busy freelancers who need more time to take up projects and do them less worried about being paid and scheduling!
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <p className="text-blue">
                                    Threats
                                </p>
                                <ul>
                                    <li>
                                        COVID-19 will impact us, and simultaneously, it will not: There will still be a demand for photographers and videographers at many weddings and smaller events as they are still taking place. Solution: Provide a small marketplace like structure within the platform to help find freelancers find work and for customers to hire professionals for gigs!
                                    </li>
                                    <li>
                                        More prominent companies may attempt to undercut us by replicating our software and ideas. Solution: Create a feeling of emotional connection to the customers. Use email marketing, blogs, podcasts, and other marketing forms to reach out to customers to keep them engaged with the brand.                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </table>

                    <p>
                        <span className="lead">Assumptions:</span>
                        <br />
                        It is crystal clear that, an interface such as Lancely can help provide most needs of photographers with user friendly, safe and organized interface. The user stories attached has proved that a tool with such capabilities is needed and if done correctly can be a very successful tool. It is important to note that, safety of our user’s information is our number one priority. We hope to deliver by the end of 2022, an efficient, safe environment which will handle everyday need of everyday freelancer.
                        <br />
                        Lastly, this platform, promises a large marketplace where users can share their work, network and connect with clients with ease.
                    </p>



                     <a id="project-requirements"></a>
                    <p className="display-6 text-blue">
                        Project/Business Requirements
                    </p>

                    <div className="img-responsive">
                        <img src={'/assets/imgs/portfolio/lancely-co-picture1.png'} alt="Requirements" />
                    </div>

                    <div className="spacer"></div>

                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th class="tg-0lax"> <br />Requirement Scope Area </th>
                                <th class="tg-0lax"> <br />Description </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="tg-0lax"> <br />Customer Relationship Management Platform </td>
                                <td class="tg-0lax"> <br />Simplified way to manage a freelancing business. Invoicing, scheduling, payments, etc. </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="spacer"></div>

                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th> <br />External Entity </th>
                                <th> <br />Description </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> <br />Freelancer </td>
                                <td> <br />The person who invoices clients, schedules, and requests payment from clients; additionally, they can upload, edit, and delete files that the client can see. </td>
                            </tr>
                            <tr>
                                <td> <br />Client </td>
                                <td> <br />Clients can schedule appointments, pay for services, and they can view and download items shared to them by a freelancer. </td>
                            </tr>
                            <tr>
                                <td> <br />Form Signing Service </td>
                                <td> <br />Clients use this to sign forms (model / content release) </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="spacer"></div>

                    <table className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th> <br />Information Flows </th>
                                <th> <br />Description </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> <br />Project Files </td>
                                <td>   <br />Any file or document (photographs or videos) that a client or freelancer can see, upload, and download through the platform   </td>
                            </tr>
                            <tr>
                                <td> <br />File Preferences </td>
                                <td>   <br />The freelancer can easily modify viewing permissions, share certain work on their profile page, etc.   </td>
                            </tr>
                            <tr>
                                <td> <br />Misc. Files </td>
                                <td>   <br />These are receipts and invoices which a freelancer can generate and share with clients.   </td>
                            </tr>
                        </tbody>
                    </table>


                     <a id="project-plan"></a>
                    <p className="display-6 text-blue">
                        Project Plan
                    </p>

                    <p>
                        <span className="lead">Executive Summary: </span>
                        <br />
                        The following describes the project to be executed.
                        <br />
                        Objective	The purpose of this document is to analyze and define high-level needs and features of our project. It focuses on:
                        <br />
                        <ul>
                            <li>Provide a platform for freelancer</li>
                            <li>Changing the world by creating software that makes lives more manageable</li>
                            <li>Freelancers will manage/schedule appointments, payments, upload work and distribute them as well</li>
                        </ul>
                        <br />
                        Corporate Goals Addressed	profit, growth, leadership
                        <br />
                        Planned Start Date	09/09/2021
                        <br /><br />
                        Planned End Date	04/01/2022
                    </p>


                    <p>
                        <span className="lead">
                            Deliverables:
                        </span>
                        <br />

                        This project will deliver the following:
                        <br />
                        A Website and Desktop Application	A website that also runs on Electron (hybrid desktop app) will be provided so that tasks can be completed on a bigger screen, tasks that cannot be done on a mobile application
                        <br />
                        A Mobile Application	Will have (almost) all of the features the website or desktop app have but will focus more on communication between the freelancers and clients.
                    </p>

                    <p>
                        <span className="lead">
                            Dependables:
                        </span>
                        <br />
                        The following are the internal and external dependencies that will have to be acknowledged and addressed.
                        <br /> <br />
                        External:
                        Phase B can not begin before phase A
                        Project approval is needed before starting the project
                        <br />
                        Internal:
                        Project can not be started without project plan
                        System testing can not be started without developing system

                    </p>

                    <p>
                        <span className="lead">
                            Risk Management:
                        </span>
                        <br />

                        <table className={`table table-bordered table-striped`}>

                            <thead>
                                <tr>
                                    <th> <br />Potential Risk </th>
                                    <th> <br />Severity (H/M/L) </th>
                                    <th> <br />Likelihood (H/M/L) </th>
                                    <th> <br />Management Strategy<br /> <br /> <br /> <br />  </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> <br />Estimated Project Schedule </td>
                                    <td> <br />L </td>
                                    <td> <br />L </td>
                                    <td> <br />Complete agenda with frequent meeting </td>
                                </tr>
                                <tr>
                                    <td> <br />Narrow Knowledge Level of Users </td>
                                    <td> <br />M </td>
                                    <td> <br />H </td>
                                    <td> <br />Peer evaluation and training </td>
                                </tr>
                                <tr>
                                    <td> <br />COVID-19 will impact us, and simultaneously, it will not: There will still be a demand for photographers and videographers at many weddings and smaller events as they are still taking place.<br /> <br />  </td>
                                    <td> <br />M </td>
                                    <td> <br />M </td>
                                    <td> <br />Provide a small marketplace like structure within the platform to help freelancers find work and for customers to hire professionals for gigs! </td>
                                </tr>
                                <tr>
                                    <td> <br />More prominent companies may attempt to undercut us by replicating our software and ideas. </td>
                                    <td> <br />M </td>
                                    <td> <br />L </td>
                                    <td> <br />Create a feeling of emotional connection to the customers. Use email marketing, blogs, podcasts, and other marketing forms to reach out to customers to keep them engaged with the brand. </td>
                                </tr>
                            </tbody>
                        </table>

                    </p>

                     <a id="wireframes"></a>
                    <p className="display-6 text-blue">
                        Wireframes/Mock ups
                    </p>

                    <iframe style={{ border: "1px solid rgba(0, 0, 0, 0.1);" }} width="100%" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FTVBVQNnAEvGE71oZwUdC9t%2FLancely-Co---Desktop---Final%3Fnode-id%3D0%253A1" allowFullScreen={true}></iframe>


                </div>
            </div>
        </div>
    )
}

export default LancelyComponent