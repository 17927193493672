import React from 'react'

import './About.css';

function About() {
    return (
        <div className="container lighter-background">
            <div className="row">
                <div className="col col-8 col-expand text-left hero">
                    <div className="mobile-only center-on-mobile">
                        <div className="img-responsive user-avatar">
                            <img src={'/assets/imgs/bg3-original.jpg'} alt="" />
                        </div>
                    </div>
                    <div className="display-4 text-bold text-indigo center-on-mobile">About Me <span className="wave">👋🏽</span></div>
                    <p className="lead">
                        My name is Omar Quazi and I’m a Software Developer with 10 years of experience designing and building powerful and intuitive programs and apps.
                    </p>
                    <p className="lead">
                        I am currently a full time Software Engineer @ Okta. If you want, you can view my <a href="https://www.linkedin.com/in/omarquazi" target="_blank">LinkedIn</a> profile, download my resume or see my latest work right here on my site.
                    </p>
                    <p className="lead">
                        <small>Psst. This entire site is built on the <a href="https://getbasket.co" target="_blank">Basket</a> framework which I designed myself from the ground up.</small>
                    </p>
                </div>
                <div className="col col-4 col-expand desktop-only">
                    <div className="img-responsive h-100 bottom-align">
                        <img src={'../../assets/imgs/bg3.png'} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
