import React, { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';

import notie from 'notie';

import portfolioData from './portfolioData.json';
import './Portfolio.css';

function Portfolio() {

    const [modalContent, setModalContent] = useState();
    const modalRef = useRef(null);

    let { projectId } = useParams();
    let navigate = useNavigate();

    const goToUrl = (url) => {
        window.location.href = url;
    }

    useEffect(() => {
        if (projectId !== undefined) {
            let item = portfolioData.filter((i) => i.id === projectId)
            if (item.length > 0) {
                // console.log(item);
                handleShow(item[0]);
            } else {
                handleClose();
            }
        } else {
            handleClose();
        }
    }, [])

    const handleClose = () => {
        window.location.hash = "";
        document.querySelector('#root').style.overflow = 'unset';
    };

    const comingSoon = () => {
        notie.alert({
            type: "success",
            text: "Coming Soon!",
            stay: false,
            time: 5,
            position: "top"
        });
    }

    const handleShow = (project) => {
        setModalContent(
            <>
                <div class="img-responsive" >
                    <img src={`/assets/imgs/portfolio/${project.img}`} style={{ maxHeight: "50vh" }} />
                </div>
                <p class="lead">{project.description}</p>
                <p class="lead">
                    {project.link !== "" ?
                        <a class='btn btn-indigo width-75' href={project.link} target='_blank'>
                            View Project
                        </a> : ""
                    }
                    {
                        project.appsComingSoon &&
                        <>
                            <br /><br />
                            <a onClick={comingSoon} style={{ cursor: "pointer" }}>

                                <div class="img-responsive" >
                                    <img src={`/assets/imgs/companies/google-play.png`} style={{ maxWidth: "150px" }} />
                                    &nbsp;
                                    <img src={`/assets/imgs/companies/apple-store.png`} style={{ maxWidth: "150px" }} />
                                </div>
                            </a>
                        </>
                    }
                </p>
            </>

        )
        window.location.hash = "#projectModal";
    };

    return (
        <>
            <div class="container" style={{ paddingTop: '10%' }}>
                <div class="row h-100">
                    <div class="col col-12 col-expand">
                        <div class="display-4 text-bold text-indigo">Portfolio</div>

                        <p>Here is my Capstone Project:</p>
                        <div class="spacer"></div>

                        <div className={`card`} onClick={() => {
                            goToUrl("/lancely")
                        }}>
                            <div class="card_image">
                                <img src={`/assets/imgs/portfolio/lancely-co.png`} />
                            </div>
                            <div class="card_title"><p>Lancely Co</p></div>
                        </div>


                        <p>Here is some of my past work:</p>
                        <div class="spacer"></div>

                    </div>
                    <div id="portfolio" class="col col-12 col-expand text-center">
                        {
                            portfolioData.map((project, index) => {
                                return (
                                    <div className={`card`} key={index} onClick={() => {
                                        handleShow(project);
                                    }}>
                                        <div class="card_image">
                                            <img src={`/assets/imgs/portfolio/${project.img}`} />
                                        </div>
                                        <div class="card_title"><p>{project.name}</p></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div class="col">
                        <div class="spacer"></div>
                    </div>
                </div>
            </div>
            <div id="projectModal" class="modal" tabindex="0" >
                <div class="modal-inner">
                    <a href="#closeModal" title="Close" class="modal-close">&times;</a>
                    <div class="modal-header text-center">
                        <h2 id="projectTitle">  </h2>
                    </div>
                    <div class="modal-body text-center">
                        <div class="container-fluid">
                            <div class="row">
                                <div id="projectBody" class="col col-12" ref={modalRef}>
                                    {modalContent}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio
