import React from 'react'

import './Home.css';

function Home() {
    return (
        <div id="home-container" className="container-fluid">
            <div className="row">
                <div className="col col-4 col-expand text-center" style={{alignItems: "center"}}>
                    <div className="img-responsive h-100 bottom-align home-image">
                        <img src={'/assets/imgs/bg.png'} alt="Me in black and white" />
                    </div>
                </div>
                <div className="col col-8 col-expand text-light h-100 home-content">
                    <div className="display-4 text-bold">Hi, I am Omar.</div>
                    <div>
                        <p className="lead">
                            I'm a web and software developer based in Toronto, Ontario, Canada.
                            <br /><br />
                            I specialize in designing, building, operating, and sometimes rescuing web 	&#38; software applications.
                            <br /><br />
                            On my free time, I like to code, do portrait photography, and go hiking!
                        </p>
                    </div>
                    <div>
                        <a href="/portfolio" className="btn btn-indigo">Check Out My Work</a>
                        &nbsp;
                        <a href="https://photobyr.id" className="btn btn-light">My Photography &#8599;</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
